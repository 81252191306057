import React from 'react'
import { 
    Box,
    Input,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Textarea,
    Button,Spinner,useToast ,Text
} from '@chakra-ui/react'
import axios from 'axios'
const Form = () => {
    const toast = useToast()
    const [errorMessage,setErrorMessage] = React.useState("")
    const [userDetails,setUserDetails] = React.useState({
        name:'',
        email:'',
        message:''
    })
    const [nameError,setNameError] = React.useState(false)
    const [emailError,setEmailError] = React.useState(false)
    const [messageError,setMessageError] = React.useState(false)
    const [isSending,setIsSending] = React.useState(false)
    const handleChange = field => {
        setUserDetails({...userDetails,...field})
    }
    const showToast = (title,status) => {
        toast({
            title,
            status,
            duration: 5000,
            isClosable: true,
          })
    }
    const sendEmail = info => {
        setIsSending(true)
        let formData = new FormData()
        formData.append('email',userDetails.email)
        formData.append('message',userDetails.message)
        formData.append('name',userDetails.name)
        axios.post('https://appsekai.online/api/ob-portfolio',formData)
        .then((response)=>{
            setIsSending(false)
            if(response.status != 200) {
                showToast('Cannot send the message, please try again!','warning')
            }else {
                showToast('Message sent successfully','success')
                setUserDetails({
                    name:'',
                    email:'',
                    message:''
                })
            }
        })
    }
    const filterAndSend = () => {
        let isEmpty = Object.values(userDetails).some(field=>(field.length === 0))
        if(isEmpty) {
            setNameError(true)
            setEmailError(true)
            setMessageError(true)
            setErrorMessage("All fields are required!")
            return 
        }
        // email validation
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        if(userDetails.name.toString().length < 3){
            setNameError(true)
            setErrorMessage("Invalid name!")
            return
        }else if(!userDetails.email.toString().match(mailformat)){
            setEmailError(true)
            setErrorMessage("Invalid email!")
            return
        }else if(userDetails.message.toString().split(" ").length < 3){
            setMessageError(true)
            setErrorMessage("Write at least 3 words!")
            return
        }else{
            // Clean ==> Send 
            sendEmail(userDetails)
        }
    }
    const handleFocus = () => {
        setNameError(false)
        setEmailError(false)
        setMessageError(false)
    }
    const shadowCssConfig = {
        borderRadius:0,
        backgroundColor:'#FEFEFE',
        boxShadow : '7px 10px #FF5F5F'
    }
    // console.log(userDetails)
    // console.log(Object.values(userDetails))
    return (
        <Box pos="relative" 
        w={{base:'100%',md:"50%"}}
        pt={10} 
        pb={1}
        px={{base:2,sm:20}} 
        ml={{base:0,md:15}} 
        borderWidth={4} 
        borderColor={"white"} 
        borderStyle={'solid'}
        >
           <FormControl isInvalid={nameError}>
              <FormLabel color='white'>What's your name?</FormLabel>
              <Input 
              type='text'  
              width={{base:'96%',md:'40%'}}
              p={5}
              value={userDetails.name}
              style={{...shadowCssConfig}}
              onChange={e=>handleChange({name:e.target.value})}
              onFocus={()=>handleFocus()}
              />
              {nameError ? <FormErrorMessage mt={4} color="#FFE300">{errorMessage}</FormErrorMessage> : ""}
           </FormControl>
           <FormControl mt={4} isInvalid={emailError}>
              <FormLabel color='white'>What's your e-mail?</FormLabel>
              <Input type='email' 
            width={{base:'96%',md:'60%'}} 
              p={5}
              value={userDetails.email}
              style={{...shadowCssConfig}}
              onChange={e=>handleChange({email:e.target.value})}
              onFocus={()=>handleFocus()}
              />
              {emailError ? <FormErrorMessage mt={4} color="#FFE300">{errorMessage}</FormErrorMessage> : ""}
           </FormControl>
           <FormControl mt={4} isInvalid={messageError}>
              <FormLabel color='white'>Your message</FormLabel>
              <Textarea 
              resize={'none'}
              value={userDetails.message}
              width={{base:'96%',md:'80%'}}
              h={150} 
              overflow={'auto'}
              style={{...shadowCssConfig}}
              onChange={e=>handleChange({message:e.target.value})}
              onFocus={()=>handleFocus()}
              />
              {messageError ? <FormErrorMessage mt={4} color="#FFE300">{errorMessage}</FormErrorMessage> : ""}
           </FormControl>
           <Button 
        //    pos="absolute"
        // left={0}
           color="#000"
           size={"lg"}
           my={7}
           style={{...shadowCssConfig}}
           onClick={()=>filterAndSend()}
           _hover={{color:'#FF5F5F'}}
           >{
               isSending ? <><Spinner /></> : <><Text>Send Message</Text></>
           }</Button>
        </Box>
    )
}

export default Form