import React from 'react'
import { Card } from './../Components/index'
import {
    Box, Heading, SimpleGrid
} from '@chakra-ui/react'

const RecentProjects = () => {
    let projects = [
        {
            title : 'Pie Chart Creator',
            description: 'Easily Create Custom Charts In Seconds.',
            link:'https://pie-chart-creator.pages.dev/',
            imgSrc:'/images/piechart.JPG'
        },
        {
            title : 'BreezyShare',
            description: 'Effortless Ephemeral Content Creation and Sharing.',
            link:'https://breezyshare.web.app/',
            imgSrc:'/images/breezyshare.JPG'
        },
        {
            title : 'ShrinkIt',
            description: 'Links Shortening Micro-App.',
            link:'https://shrinkit-2c51c.web.app/',
            imgSrc:'/images/Shrinkit.jpg'
        },
        {
            title : 'Mega Fitness API',
            description: 'Cloud-Based API with more than 15K Users. Many users have integrated it in their production applications.',
            link:'https://rapidapi.com/fr/bejjaothmane/api/mega-fitness-calculator1/',
            imgSrc:'/images/api.JPG'
        },
        {
            title : 'Setupshub',
            description: 'Mini Social Network Dedicated for Setups Lovers, Built with ReactJs &amp; Firebase.',
            link:'https://www.setupshub.com',
            imgSrc:'/images/Setupshub.jpg'
        },
        {
            title : 'Fountain Pen App',
            description: 'React Native Mobile Application to sell Fountain Pens.',
            link:'https://github.com/escajs/fountain-pen-rn',
            imgSrc:'/images/pen.JPG'
        },
    ]
    return (
        <Box
            minH={'100vh'}
           bgGradient={"linear(to-r, #c0392b, #8e44ad)"}
            px={'2rem'}
            pb={{ base: 10}}
            id="portfolio"
            
        >
            <Heading
                as="h1"
                m={0}
                color="white"
                bg="transparent"
                py={{ base: 50, lg: 100 }}
            >
                Recent Projects
            </Heading>
            <SimpleGrid
                columns={{ sm: 1, md: 2, lg: 3,xl:4 }}
                spacing={30}
                placeItems={'center'}
            >
                {projects.map(project=> {
                    return <Card 
                    title={project.title}
                    description={project.description}
                    link={project.link}
                    imgSrc={project.imgSrc}
                    />
                })}
            </SimpleGrid>
        </Box>
    )
}

export default RecentProjects