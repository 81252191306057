import React from 'react'
import Design from './../images/design.svg'
import {
    Box,Container,Flex, Heading, Image
} from '@chakra-ui/react'
const WhoAmI = () => {
    return (
        <Flex 
        minH={'100vh'} 
        //bgColor={'#B24C4C'} 
        px={'2rem'} 
        bgColor="#B24C4C"
        direction={{base:'column',md:'row'}}
        py={{base:10}}
        align="center"
        justify={"center"}
        color="white"
        id="about"
        >
      <Box w={{base:"100%",md:"40%"}} 
    //   bgColor="orange"
      >
         <Box pos="relative">
         <Box
          bgColor={"rgba(0, 0, 0, 0.7)"}
          w={200}
          minH={200}
          borderRadius={"50%"}
          ></Box>
         <Heading as="h2" size={"md"} pos="absolute"
         bottom={5}
         left={100}
         fontWeight={"bold"}
         >I'm Also UI/UX Designer</Heading>
         </Box>
         
            <Container maxW={"container.sm"}>
            <Box
            letterSpacing={1}
            px={{base:5,md:50}} fontWeight={"semibold"} py={7} fontSize={20}>
            I help small agencies and startups to bring their ideas to life. From Design to deployment By the most stable technologies and services.
            </Box>
            </Container>
         
      </Box>
      <Box w={{base:"100%",md:"60%"}}>
         <Image
         src={Design}
         />
      </Box>
        </Flex>
    )
}

export default WhoAmI