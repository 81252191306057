import React from 'react'
import { Box,Text,Flex,Image } from '@chakra-ui/react'
import ContactArrow from './../../../images/ContactArrow.svg'
const ContactMessage = () => {
    return (
        <Flex direction={'column'} align={"center"}>
            <Text fontSize={30} color="#FEFEFE">Thinking of a project?</Text>    
            <Box mt={10}>
            <Text fontSize={34} color={"#FF5F5F"}>CONTACT ME</Text>
            <Image 
            src={ContactArrow}
            display={{base:'none',md:'block'}}
            />
            </Box> 
        </Flex>
    )
}

export default ContactMessage