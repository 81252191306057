import React from 'react'
import Header from './Sections/Header';
import './App.css'
import WhoAmI from './Sections/WhoAmI';
import RecentProjects from './Sections/RecentProjects';
import Contact from './Sections/Contact';
import Footer from './Sections/Footer';
function App() {
  return (
   <>
   <Header />
   <WhoAmI />
   <RecentProjects />
   <Contact />
  <Footer />
   </>
  );
}

export default App;
