import React from 'react'
import { SiGithub, SiBehance, SiLinkedin } from "react-icons/si"
import {
    Flex,
    Center,
    LinkBox,
    LinkOverlay
} from "@chakra-ui/react"

const SocialNetwork = (props) => {
    return <Flex
    transform="auto"
    translateY={{base:-120,md:0}}
    zIndex={7} {...props} pos={{md:'absolute'}} left={{md:-7,xl:0}} direction={{base:'row',md:'column'}} gap={4}>
        <LinkBox shadow={"md"}>
            <Center h={10} w={10} bg="#FF5F5F" className="social_icons">
                <LinkOverlay isExternal={true} href="https://github.com/escajs">
                    <SiGithub />
                </LinkOverlay>
            </Center>
        </LinkBox>
        <LinkBox mt={{md:3.5}} shadow={"md"}>
            <Center h={10} w={10} bg="#FF5F5F" className="social_icons">
                <LinkOverlay isExternal={true} href="https://www.behance.net/othmanebejja">
                    <SiBehance />
                </LinkOverlay>
            </Center>
        </LinkBox>
        <LinkBox mt={{md:3.5}} shadow={"md"}>
            <Center h={10} w={10} bg="#FF5F5F" className="social_icons">
                <LinkOverlay isExternal={true} href="https://www.linkedin.com/in/othmane-bejja-16a910212">
                    <SiLinkedin />
                </LinkOverlay>
            </Center>
        </LinkBox>
    </Flex>
}

export default SocialNetwork