import React,{useRef} from 'react'
import { SocialNetwork, ScrollDown } from '../../Components/index'
import BO from './../../images/bejja_othmane.jpg'
import Signature from './../../images/Signature.svg'
import {
    Box,
    Image,
    Flex,
    Heading,
    Text
} from '@chakra-ui/react'
import axios from 'axios'
const Hero = ({mousePosition}) => {
    
    const fixedCircleRef = useRef(null)
    const animatedCircleRef = useRef(null)
    const animateCircle = () => {
        let fixedCircle = fixedCircleRef.current
        let animatedCircle = animatedCircleRef.current
        let dx = mousePosition.x - fixedCircle.x;
        let dy = mousePosition.y - fixedCircle.y;
        let theta = Math.atan2(dy, dx);
        setTimeout(()=>{
            let circleAbc = -(dx/20 + Math.cos(theta) * 5)
            let cirlceOrd = -(dy/5 + Math.sin(theta) * 5)
             animatedCircle.style.transform = `translate(${circleAbc}px,${cirlceOrd}px)`
        },50)
    }
    const heroImageCssConfig = {
        borderRadius: '50%',
        zIndex: 10
    }
    const animatedCircleCssConfig = {
        position: 'absolute',
        borderRadius: '50%',
        backgroundColor: '#FF5F5F',
        top: 20,
        zIndex: 5
    }


    return <Flex
        bgColor={'transparent'}
        minH={"calc(100vh - 72.727px)"}
        align="center"
        justify={"center"}
        direction={{base:'column',md:'row'}}
        pos="relative"
        onMouseMove={animateCircle}
    >

        <SocialNetwork display={{base:'none',lg:'block'}}/>
        <Box
            border={3}
            borderStyle={"transparent"}
            borderColor={"white"}
            pos="relative"
        >
            <Image
                src={BO}
                style={{ ...heroImageCssConfig }}
                border="6px"
                borderStyle={'solid'}
                borderColor={'#fff'}
                pos={'relative'}
                h={{base : 250,sm:270,md:370}}
                w={{base : 250,sm:270,md:370}}
                my={{base:8,md:0}}
                ref={fixedCircleRef}
            />
            <Box 
            style={{ ...animatedCircleCssConfig }}
            minH={{base : 250,sm:270,md:370}}
            w={{base : 250,sm:270,md:370}}
            ref={animatedCircleRef}
            ></Box>
        </Box>
        <Box ml={7} color={"#fff"} textAlign={{base:'center',md:'left'}} pos={"relative"} zIndex={7}>
            <Heading as="h2" fontSize={{base:17.95,sm:18.95,lg:25.26,xl:33.67}}>My name is Othmane Bejja</Heading>
            <Heading as="h1" fontSize={{base:21.33,sm:28.43,md:37.90,lg:50.52,xl:67.34}}>I'm Full Stack Developer <br />&amp;&nbsp;
            <Text bgGradient='linear(to-r,#FF5F5F,#8e44ad )' bgClip="text" display={'inline'}>Melophile
            </Text></Heading>
            <Image
                src={Signature}
                display={{base:'none',md:'block'}}
                mt={3}
            />
        </Box>
        <ScrollDown />
    </Flex>
}
export default Hero