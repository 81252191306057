import React from 'react'
import ArrowDown from './../../images/arrowDown.svg'
import {
    Box,
    Image,
    Text
} from "@chakra-ui/react"
const scrollDownCssConfig = {
    transform: 'rotate(90deg)',
    display: 'flex',
    alignItems: 'center'
}
const ScrollDown = () => {
    return <Box 
    pos="absolute" 
    bottom={{base:10,md:110}} 
    right={0} 
    bgColor='transparent'
    style={{ ...scrollDownCssConfig }}
    className='arrow-scroll--down--trigger'
    onClick={()=>window.scrollTo(0,window.screen.height - 150)}
    display= {{base:'none',lg:'block'}}
    >
            <Text fontSize={20} color="white" display={{base:'none',lg:'block'}}>
                PUSH ME
            </Text>
            <Image
                src={ArrowDown}
                style={{ transform: 'rotate(-90deg)' }}
                ml={16}
                display={{base:'none',lg:'block'}}
            />
        </Box>
}

export default ScrollDown