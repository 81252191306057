import React from 'react'
import { HiOutlineMenuAlt1 } from 'react-icons/hi'
import Logo from './../../images/logo.svg'
import {
    Box,
    Flex,
    Image,
    UnorderedList,
    ListItem,
    Link,
    Container,
    Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure
} from "@chakra-ui/react"
import { SocialNetwork } from '..'

const Navbar = () => {
    const {isOpen,onClose,onOpen} = useDisclosure()
    const btnRef = React.useRef()
    const UnorderedListConfig = {
        listStyle: 'none',
        fontSize: '20px',
        color: '#fff'
    }
    return <Container maxW={'container.xl'}>
        <Flex 
        justify={"space-between"} 
        bgColor={"transparent"} 
        align={'center'} 
        py={1} 
        width={{base:"55%",md:'100%'}} 
        direction={{base:'row-reverse',md:'row'}}>
            <Image src={Logo} zIndex={7} />
            <Box display={{base:'none',md:'block'}}>
                <UnorderedList display={"flex"} style={{ ...UnorderedListConfig }}>
                    <ListItem mr={10}><Link href="#about" className='nav-links'>ABOUT</Link></ListItem>
                    <ListItem mr={10}><Link href="#portfolio" className='nav-links'>SHOWCASE</Link></ListItem>
                    <ListItem><Link href="#contact" className='nav-links'>CONTACT</Link></ListItem>
                </UnorderedList>
            </Box>
            <Box display={{base:'bolck',md:'none'}} ref={btnRef} onClick={onOpen}>
            <HiOutlineMenuAlt1 fontSize={30} color="#fff" />
            </Box>
        </Flex>
      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
        finalFocusRef={btnRef}
        size='full'
        isFullHeight={true}
      >
        <DrawerOverlay />
        <DrawerContent bgColor={'#EDC5C5'}>
          <DrawerCloseButton />
          <DrawerBody 
          display={"flex"}
          alignItems="center"
          justifyContent={"center"}
          gap={20}
          fontSize="3xl"
          pl={0}
          >
          <UnorderedList style={{listStyle:"none"}} textAlign="center">
                    <ListItem onClick={()=>{window.setTimeout(()=>{onClose()},100)}}><Link href="#about" className='nav-links'>ABOUT</Link></ListItem>
                    <ListItem onClick={()=>{window.setTimeout(()=>{onClose()},100)}}><Link href="#portfolio" className='nav-links'>SHOWCASE</Link></ListItem>
                    <ListItem onClick={()=>{window.setTimeout(()=>{onClose()},100)}}><Link href="#contact" className='nav-links'>CONTACT</Link></ListItem>
            </UnorderedList>
          </DrawerBody>

          <DrawerFooter h={100} display={'flex'} direction="column" alignItems="center" justifyContent={"center"}>
            <SocialNetwork />
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Container>
}

export default Navbar