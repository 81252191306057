import React,{useRef,useState} from 'react'
import { Navbar, Hero } from './../Components/index'
import {
    Box
} from '@chakra-ui/react'
const Header = () => {
  const headerRef = useRef(null)
  const [mousePosition,setMousePosition] = useState({x:0,y:0})
  React.useLayoutEffect(()=>{
    headerRef.current.addEventListener('mousemove',(e)=>{
        setMousePosition({...mousePosition,x:e.x,y:e.y})
    })
  },[])
    return (
        <Box
            minH={'100vh'}
            bgColor={'#B24C4C'}
            px={{base:0,sm:'2rem'}}
            ref={headerRef}
        >
            <Navbar />
            <Hero mousePosition={mousePosition} />
        </Box>
    )
}

export default Header