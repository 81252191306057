import React from 'react'

import { 
    Flex, LinkBox, LinkOverlay,Text,Box

} from '@chakra-ui/react'
const footerCssConfig = {

}
const Footer = () => {
return <Flex
direction={"column"}
minH={100}
bgGradient={"linear(to-r, #c0392b, #8e44ad)"}
align="center"
justify={"center"}
color="#F2FFE9"
>
<h2>Handcrafted by me &copy; 2021</h2>
</Flex>
}

export default Footer