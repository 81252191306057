import React from 'react'
import { Box,Heading,Image,Text,LinkBox,LinkOverlay } from '@chakra-ui/react'

import { HiExternalLink } from 'react-icons/hi'
const Card = ({title,description,link,imgSrc}) => {
    const cardCssConfig = {
        boxShadow: '10px 12px #FF5F5F'
    }
    return (
        <Box pos='relative'>
            <Box style={{ ...cardCssConfig }} bg="#C55656" w={300} minH={350} maxH={350} mt={3} borderRadius={20}>
               <Box className="card">
                   <Image 
                   src={imgSrc}
                   width={'100%'}
                   borderRadius={"20px 20px 0 0"}
                   />
                  <Box px={3} className="btn-trigger">
                    <LinkBox>
                    <Heading className="card-btn" as="h1" size={"md"} my={2} shadow={"md"} borderRadius={4} display={"flex"} align="center" gap={1.5}
                  width={"max-content"}
                  p={1}
                  >
                       <Text letterSpacing={1.5}>{title}</Text><LinkOverlay href={link} isExternal={true}><HiExternalLink /></LinkOverlay>
                   </Heading>
                    </LinkBox>
                   <Text color='whiteAlpha.800' fontWeight={'medium'}>
                    {description}
                   </Text>
                  </Box>
               </Box>
            </Box>
        </Box>
    )
}

export default Card