import React from 'react'
import ContactMessage from '../Components/Contact/Message/Message'
import Form from '../Components/Contact/Form/Form'
import {
    Flex
} from '@chakra-ui/react'
const Contact = () => {
    return (
        <Flex
            minH={'100vh'}
            bgColor={'#B24C4C'}
            px={{ sm: 0, md: '2rem' }}
            pt={{ base: "2rem", md: 0 }}
            pb={{base:10,md:0}}
            align={'center'}
            justify={'center'}
            gap={{ md: 20 }}
            direction={{ base: 'column', md: 'row' }}
            id="contact"
        >
            <ContactMessage />
            <Form />
        </Flex>
    )
}

export default Contact